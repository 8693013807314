import React, { useState } from 'react';
import Layout from 'components/layout';

import Confirmation from './confirmation';
import RSVPForm from './form';
import styles from './rsvp-page.module.scss';

const RSVPPage = ({ toggleLoader }) => {
  const [ showConfirmation, showSuccess ] = useState(false);
  const [ attending, submitAttending ] = useState();

  const handleSubmit = (attending) => {
    showSuccess(true);
    submitAttending(attending);
  };

  return (
    <div className={styles.rsvpContent}>
      { showConfirmation ? 
        <Confirmation attending={attending} /> :
        <RSVPForm handleSubmit={handleSubmit} toggleLoader={toggleLoader} />
      }
    </div>
  );
};

export default () => (
  <Layout>
    <RSVPPage />
  </Layout>
);